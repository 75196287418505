/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andri Samsuri
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;  
    position: relative;
    overflow-x: hidden;
    background: #fff; 
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-size: 14px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #888;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-family: "Open Sans", sans-serif;
}
input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
} 
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }

.after_clear{content:"";clear: both;display: block;}

a {@include transition(all .15s ease-in-out);} 

.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.flex-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;}  

/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper {     
    width: 1300px;margin: 0 auto; position: relative;     
    @include boxSizing(border-box);
    &:after{@extend .after_clear;} 
}

.btn {
    display: inline-block; height: 50px; line-height: 50px; font-family: 'Fredoka One'; text-align: center;
    position: relative; cursor: pointer;  
    @include boxSizing(border-box); 
    &.btn-primary {
        color: #19515f; font-size: 18px; padding: 0 50px; background: #ffb006;  
        span {position: relative;} 
        @include borderRadius(3px); 
        &:hover {
            background: #ffa406;  
        }
        &.sm {padding: 0 25px;}
        &.md {
            font-size: 16px; margin: 0 8px;  
            @include borderRadius(30px); 
        }
        &.green { 
            background: #19515f; color:#ffb006;  
            &:hover {
                background:#124855;
            } 
        }
        &.radius {
            @include borderRadius(30px);
        }
        &.vote {
            padding: 0 35px; 
            .txt {              
                padding: 0 0 0 30px;   
                &:before {
                    position:absolute; width: 20px; height: 100%; content: ""; left: 0; top: 0;   
                    background: url('../images/material/ico-love.png') no-repeat center center;   
                }
                span {font-family: 'Fredoka One'}
            }
        }
    }
    &.btn-secondary { 
        color: #fff; font-size: 16px; padding: 0 30px; background: #31798c;   
        border:3px solid #31798c; line-height: 42px; margin: 0 10px; 
        @include borderRadius(30px); 
        &.white {
            color: #31798c; background: #fff; 
            &:hover {color:#fff; background: #31798c; border-color:#31798c;}      
        }
        &.white-text { 
            color:#fff; text-decoration: none;              
        }  
        &:hover { 
            background: #28697a; border-color:#28697a;     
        }
    }    
    &.abs {
        position: absolute; left: 50%; bottom: 104px; z-index: 5;   
        @include transform(translate(-50%,0));  
    } 
    &.primary-sm {
        height: 40px; line-height: 40px; padding: 0 30px; font-size: 16px; 
        @include borderRadius(30px); 
    }
    &.primary-md {
        height: 45px; line-height: 45px; padding: 0 30px; font-size: 16px;   
        @include borderRadius(30px); 
    }
    &.btn-catalog {
        padding: 0 22px; border:1px solid #d7d7d7; background: #f3f3f3; margin: 0 15px 0 0;   
        @include borderRadius(5px); 
        span {
            display: inline-block; padding:0 0 0 28px; position: relative;  
            &:before {
                position:absolute; width: 19px; height: 100%; left: 0; top: 0; content: ""; 
                background: url('../images/material/ico-download.png') no-repeat center center; 
            }
        }
        &:hover {
            background: #e6e5e5; border-color:#e6e5e5; 
        }
    }
}
.btn-play { 
    position: absolute; width: 126px; height: 126px; top: 50%; left: 50%; z-index: 5; 
    background: url('../images/material/btn-play.png') no-repeat center center; margin-top: 0; 
    text-indent: -999999px;    
    @include transform(translate(-50%,-50%)); 
    &.play-default {margin-top: 0;}
    &.right {
        left: 100%; top: 100%; margin-left: -146px; margin-top: -146px; 
        @include transform(translate(0,0));
    }
}
.btn-play-sm { 
    position: absolute; width: 88px; height: 88px; top: 50%; left: 50%; z-index: 5; 
    background: url('../images/material/btn-play-sm.png') no-repeat center center; 
    text-indent: -999999px;    
    @include transform(translate(-50%,-50%)); 
}
.btn-post {
    display: inline-block; padding: 0 30px; height: 40px; line-height: 40px; text-align: center; 
    background: #ffb006; vertical-align: top; font-size: 16px; color: #19515f; font-weight: bold; 
    @include borderRadius(30px); 
    span { 
        display: inline-block; position: relative; padding: 0 0 0 30px;   
        &:before {
            position:absolute; width: 20px; height: 100%; content: ""; left: 0; top: 0; 
            background: url('../images/material/ico-edit.png') no-repeat center center; 
        }
    }
    &.bp-right {float: right; margin-top: -10px;}  
} 
.btn-view {
    display: inline-block; color: #317a8d; font-weight: bold;
}
a.link-abs {position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 3;}   

.loader {
    position: fixed; width: 100%; height: 100%; top: 0; left: 0; z-index: 999999;
    .logo {  
        position: absolute; width: 240px; height: 91px; left: 0; top: 0; bottom: 0; right: 0;
        margin: auto; z-index: 3;            
    } 
    .bg { 
        background: #fff; position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 2; 
    }
} 


/* header
----------------------------------------------------------------------------------------------*/
.box-lang { 
    float: left; margin: 0 28px 0 0;
    .select { 
        position: relative;  
        &:after {
            position:absolute; width: 14px; height: 100%; content: ""; right: 0; top: 0; 
            background: url('../images/material/ico-arrow-v.png') no-repeat center center; z-index: 1;  
        }
        .selectCurrent { 
            padding: 0 22px 0 10px; color: #888; font-weight: bold; text-transform: uppercase; border:0; 
            line-height: 26px; height: 26px; cursor: pointer; position: relative; z-index: 3;
        }
        .selectDrop {           
            background: #31798c; padding:7px 10px; border: 1px solid #31798c; height: auto;
            display: none; 
            position: absolute; top: 100%; right: 0; width: 100%; color: #fff;
            @include boxSizing(border-box);  
            a {
                padding: 0 0 0 0; color: #fff; font-weight: bold; text-transform: uppercase; border:0; 
                line-height: 26px; height: 26px; cursor: pointer; position: relative; z-index: 3;
                display: block; 
            }
        }
        select {
            padding: 0 22px 0 0; color: #888; font-weight: bold; text-transform: uppercase; border:0; 
            line-height: 26px; height: 26px; cursor: pointer; position: relative; z-index: 3; 
            background: none; 
            option {
                &.act {height: 0; display: none;}
            }
        }
    } 
}
.join {
    float: left; 
    a {
        display: block; font-size: 16px; color: #19515f; font-weight: bold; 
    }  
    &.right {
        float: right; margin: 3px 0 0 0;  
    }
} 
header {
    position: fixed; width: 100%; top: 0; left: 0; z-index: 8888; 
    .wrapper {
        width: 1400px; background: #fff; height: 110px; margin-top: 50px; 
        border-left:9px solid #f9af40;    
        @include borderRadius(5px);  
        @include boxSizing(border-box); 
    }
    .burger-menu {
        display: none; 
    }
    .nav {
        ul { 
            font-size: 0; padding: 0 0 0 40px;  
            li { 
                display: inline-block; font-size: 16px; font-weight: 550; text-transform: uppercase;  
                a {
                    color: #111; display: block; padding: 0 17px; line-height: 26px;   
                    &:hover, 
                    &.active {
                        color:#ffb006; 
                    }  
                } 
            }
        }
    }  
    .logo {  
        position: absolute; width: 180px; height: 63px; left: 50%; top: 50%;  
        a {display: block;}  
        @include transform(translate(-50%, -50%));
    }
    .left {
        position: relative; height: 100%;padding: 42px 0 42px 0; 
        @include boxSizing(border-box); 
    }
    .right {   
        height: 100%; background: #fff; position: relative; z-index: 4;
        @include boxSizing(border-box);  
        @include borderRadius(5px);
        .nav ul {padding-left: 0px;}   
    }
    .box-other { 
        padding: 42px 30px 0 30px; position: relative; margin-left: 10px;          
        @include boxSizing(border-box);
        div {
            line-height: 26px; height: 26px; 
        }
        &:before {
            position: absolute; width: 1px; height: 26px; content: "";
            background: #bbb; top: 50%; left: 0; margin-top: 0;  
            @include transform(translate(-50%, -50%));
        }
    }    
    .box-search { 
        float: left; margin: 0 35px 0 0;
        .icon-sc { 
            width: 23px; height: 26px; cursor: pointer; 
            background: url('../images/material/ico-search.png') no-repeat center center;  
        }
    }    
    .view-search { 
        position: absolute; top: 100%; right: 0; background: #31798c; margin-top: -60px; 
        width: 400px; height: 50px; z-index: 2;
        @include placeholder{color: #fff;} 
        input {
            width: 100%; height: 100%; padding: 10px 50px 10px 20px; background: none;border:0;  
            font-style: italic; color: #fff; 
            @include boxSizing(border-box);  
            &[type="submit"] {
                position: absolute; width: 50px; height: 100%; top: 0; right: 0;cursor: pointer; padding: 0;
                background: url('../images/material/ico-search-fff.png') no-repeat center center;
                @include transition(all .15s ease-in-out); 
                &:hover { 
                    opacity:0.6; 
                }
            }
        }
    } 
}


/* middle
----------------------------------------------------------------------------------------------*/
.middle {
    padding: 0 0 0 0; min-height: 640px; z-index: 6;   
     &.bg-retail-detail {    
        /*min-height: 1200px; */
        min-height: 960px;   
    }   
}

.contentpage { 
    header {
        border-bottom: 3px solid #317a8d; background: #fff;  
        @include boxSizing(border-box); 
        .wrapper {
            margin-top: 0; border: 0; width: 1300px;    
        }
        .nav {
            ul {
                padding: 0;
                li {
                    &:first-child { 
                        a {padding-left:0;}   
                    }
                } 
            }
        }
        .right {padding-right: 0;}  
        .box-other {
            @include borderRadius(0);
        }
    }
    .middle {padding-top: 110px;}       
} 
.contentpage2 {  
    .wrapper {width: 1170px;} 
    header {
        .wrapper {width: 1400px;} 
        &.full {  
            border-bottom: 3px solid #317a8d; background: #fff;   
            @include boxSizing(border-box); 
            .wrapper {
                margin-top: 0; height: 120px; border: 0;     
            } 
        }
    }
}

.section-home { 
    position: relative; 
    &.section-1 {
        z-index: 3; 
        background: url('../images/material/section-bg-1.png') no-repeat center top;
    }
    &.section-2 { 
        z-index: 2; 
        background: url('../images/material/section-bg-2.png') no-repeat center top;
    }
    &.section-3 {
        z-index: 1;  
        background: url('../images/material/section-bg-3.png') no-repeat center top; 
    }
    /*&.section-3.active {padding-bottom: 200px;}  */
    &.section-3.space {padding-bottom: 200px;}    
    .row-box {
        @include boxSizing(border-box);   
        &.styled-1 {
            height: 948px; background: url('../images/material/bg-hm-section-1.png') no-repeat center top;
            .wrapper {padding-top:310px; width: 1515px;}  
        }  
        &.styled-2 {             
            height: 748px; 
            background: url('../images/material/bg-hm-section-2.png') no-repeat center 0; 
            .wrapper {padding-top:150px; width: 1465px;} 
        }  
        &.styled-3 { 
            height: 789px; background: url('../images/material/bg-hm-section-3.png') no-repeat center top; 
            .wrapper {padding-top:232px; width: 1600px;}     
        }  
    } 
    .row-text {  
        border-top: 8px solid #31798c; background: #fff; position: fixed; width: 100%; left: 0; bottom: -40px;
        z-index: 6; height: 228px; display: none;     
        @include boxSizing(border-box);   
        &.box-default {position: absolute; bottom: 0;}  
        &.styled-text-1 {} 
        &.styled-text-2 {bottom: -10px;}  
        &.styled-text-3 {bottom: -10px;}  
        
        h3 {
            color: #2194b2; font-family: 'Fredoka One'; font-size: 30px; margin: 0 0 15px 0;
            a {
                color: #2194b2;
            }
        }  
        .wrapper {
            padding: 58px 55px 50px 127px;     
        }
        .short-text {
            font-size: 18px; line-height: 30px; margin: 0; max-height: 65px; overflow: hidden;       
        } 
        .left {
            width: 66.5%; padding: 0 35px 0 0;   
            @include boxSizing(border-box);
        }
        .right {
            width: 33.5%; padding: 50px 0 0 0;    
            .ic-home {
                position: absolute; width: 105px; height: 95px; content: ""; bottom: 100%;  
                left: 50%; margin-bottom: 3px; 
                background: url('../images/material/ic-home.png') no-repeat center top;   
                @include transform(translate(-50%, 0));   
            }
        }          
    }
    &.active {
        .row-text {display: block;} 
    }
}

.boxscroll-trigger { 
    position: absolute; left: 65px; top:65px; width: 60px; height: 95px; display: table;  
    .btn-trigger {
        display: table-cell; vertical-align: middle; 
        .btn-arrow {
            width: 24px; height: 24px; display: block; background: #ffb006; text-indent: -999999px; 
            cursor: pointer; position: relative;    
            border: 7px solid #fff;
            border-bottom: 7px solid #fff;  
            @include borderRadius(9px);  
            @include transition(all .2s ease-in-out); 
            &.arrow-next {
                &:after {
                    position:absolute; width: 16px; height: 8px; content: ""; left: 50%; top: 50%;  
                    background: url('../images/material/ico-arrow-v-2.png') no-repeat center center; 
                    @include transform(translate(-50%,-50%));  
                }
            }  
            &.arrow-prev {
                &:after {
                    position:absolute; width: 16px; height: 8px; content: ""; left: 50%; top: 50%;   
                    background: url('../images/material/ico-arrow-v-2-r.png') no-repeat center center; 
                    @include transform(translate(-50%,-50%)); 
                }
            }  
            &:hover {
                opacity: 0.8; 
            }
        }
    }

}

.box-abs-md {
    position: absolute; left: 50%; bottom: 104px; z-index: 5; width: 100%; text-align: center;     
    @include transform(translate(-50%,0));
}
.box-abs-lg {
    position: absolute; left: 50%; bottom: 45px; z-index: 5; width: 100%; text-align: center;     
    @include transform(translate(-50%,0));
}
.box-abs-sm {
    position: absolute; left: 50%; bottom: -14px; z-index: 5; width: 100%; text-align: center;     
    @include transform(translate(-50%,0));
}

.boxlist-md {   
    height: 397px; background: url('../images/material/boxlist-md.png') no-repeat center 33px;   
    &:after {@extend .after_clear} 
    .box {
        float: left; width: 335px; margin: 0 42px 0 0; height: 100%; position: relative;  
        @include boxSizing(border-box); 
        &:before {
            position:absolute; width: 100%; height: 100%; content: ""; z-index: 2;
            background: url('../images/material/boxlist-md-jendela.png') no-repeat left center; 
        }    
        figure { 
            width: 179px; height: 296px; margin:20px 0 0 79px; position: relative; 
            line-height: 0; overflow: hidden;
            a {display: block;}
            img {
                width: 100%; height: 100%; object-fit: cover;  
                @include transition(all .3s ease-in-out); 
            }            
        }
        .link-abs {width: 179px; height: 296px; margin:20px 0 0 79px;}
        &:nth-child(1) {} 
        &:nth-child(2) {
            margin-right: 91px;             
        }    
        &:nth-child(3) {}
        &:nth-child(4) {}
        &:last-child {margin-right: 0;} 
        &:hover {
            figure {
                img {
                    @include transform(scale(1.1)); 
                }
            }
        }
        &.unfilled {
             &:before {
                background: url('../images/material/boxlist-md-jendela-unfilled.png') no-repeat left center; 
            } 
            a {cursor: default; pointer-events: none;}
            .box-abs-md {
                a {cursor: default; pointer-events: none;} 
            }
        }
    }
}
.boxlist-lg {         
    &:after {@extend .after_clear} 
    .box {
        float: left; width: 367px; height: 370px; position: relative; margin: 38px 85px 0 0;  
        @include boxSizing(border-box);  
        &:before {
            position:absolute; width: 100%; height: 100%; content: ""; z-index: 2;  
            background: url('../images/material/boxlist-lg-jendela.png') no-repeat left center; 
        }  
        figure { 
            width: 282px; height: 315px; margin:23px 0 0 41px; position: relative; z-index: 1; 
            line-height: 0; overflow: hidden;
            img {
                width: 100%; height: 100%; object-fit: cover; 
                @include transition(all .3s ease-in-out);
            }         
            iframe {
                width: 100% !important; height: 100% !important; object-fit: cover; 
            }   
        }   
        .link-abs {width: 282px; height: 315px; margin:23px 0 0 41px;}       
        &:nth-child(2) { 
            width: 555px; height: 411px; 
            margin-top: 0;  
            &:before {                
                background: url('../images/material/boxlist-lg-center-jendela.png') no-repeat left center; 
            }
            figure { 
                width: 312px; height: 355px; margin-left: 120px; 
            }
            .link-abs {width: 312px; height: 355px; margin-left: 120px;}
        }    
        &:last-child {margin-right: 0;} 
        &:hover {
            figure {
                img {
                    @include transform(scale(1.1)); 
                }
            }
        }
    }
}
.boxlist-sm {   
    height: 295px;  
    &:after {@extend .after_clear} 
    .box {
        float: left; width: 290px; margin: 0 35px 0 0; height: 282px; position: relative;  
        @include boxSizing(border-box); 
        &:before {
            position:absolute; width: 100%; height: 100%; content: ""; z-index: 2;
            background: url('../images/material/boxlist-sm-jendela.png') no-repeat left center; 
        }    
        figure { 
            width: 184px; height: 244px; margin:16px 0 0 53px; position: relative;   
            line-height: 0; overflow: hidden;
            img {
                width: 100%; height: 100%; object-fit: cover; 
                @include transition(all .3s ease-in-out);
            }            
        }      
        .link-abs {width: 184px; height: 244px; margin:16px 0 0 53px;}  
        &:nth-child(2) {
            margin-right: 350px;             
        }           
        &:last-child {margin-right: 0;}  
        &:hover {
            figure {
                img {
                    @include transform(scale(1.1)); 
                }
            }
        }
    }
}

.banner-page {   
    border-bottom: 8px solid #31798c;    
    figure {
        line-height: 0; overflow: hidden;
        img {}
    }   
}

.breadcrumb {   
    background: #f4f4f4; position: relative; z-index: 5;    
    ul {
        li {
            display: inline-block; margin: 0 -4px 0 0; color:#999; padding: 0 25px 0 25px; position: relative; 
            &:after { 
                position:absolute; width: 8px; height: 12px; content: ""; top: 17px; right: 0; 
                background: url('../images/material/ico-arrow-h.png') no-repeat center center; 
            } 
            a {  
                display: block; padding: 15px 0; color:#999;  
            }
            &:first-child {padding-left: 0;} 
            &:last-child {
                &:after {display:none;} 
            } 
        }
    }
}
.nav-sub {
    border-bottom: 1px solid #ebebeb; margin-bottom: 35px; 
    &:after {@extend .after_clear} 
    ul {
        display: inline-block; 
        li {
            display: inline-block; padding: 0 10px; 
            a {
                display: block; font-size: 18px; color: #999; padding: 0 0 30px 0; position: relative; 
                &:after { 
                    position:absolute; width: 0; height: 4px; background: #ffb006; left: 50%; bottom: 0;  
                    content: ""; 
                    @include transition(all .15s ease-in-out);  
                    @include transform(translate(-50%, 0)); 
                }    
                &.active,
                &:hover {   
                    color: #19515f; font-weight: bold;   
                    &:after {
                        width:100%; 
                    }
                }
            }
        }
    }
}

.slick-arrow { 
    position: absolute; width: 25px; height: 25px; top: 50%; padding: 0; border:0; text-indent: -999999px;  
    z-index: 5; cursor: pointer; margin-top: -15px;   
    @include transform(translate(0 -50%)); 
    &.slick-next {
        background: url('../images/material/ic-arr.png') no-repeat center center;
        right: 0; 
    }
    &.slick-prev {
        background: url('../images/material/ic-arr.png') no-repeat center center;
        left: 0;
        @include transform(rotate(-180deg)); 
    }
}
.slick-dots { 
    position: absolute; width: 100%; left: 0; bottom: -30px; text-align: center; margin: 0 !important;  
    li {
        display: inline-block; margin: 0 5px !important; height: 14px; line-height: 14px !important;      
        padding: 0 !important;      
        button {
            cursor: pointer; width: 14px; height: 14px; background: #d8d8d8;   
            border:2px solid #fff; padding: 0; text-indent: -999999px; 
            @include borderRadius(50%);
        }
        &:before {display: none;}
        &.slick-active {
            button { 
                background: #317a8d; border-color: #317a8d;   
            }
        } 
    } 
}
.burger-filter {
    float: right; display: none; 
}
.row-main {   
    padding: 50px 0 80px 0; position: relative; z-index: 4;   
    .side {
        float: left; width: 15.385%; @include boxSizing(border-box);
        &.sd-right {
            width: 25%; padding: 25px 35px; background: #f8f8f8; 
            border:1px solid #eaeaea; 
            @include borderRadius(5px); 
            h3 {font-size: 20px; margin: 0 0 20px 0; font-weight: bold;}   
        }
    }   
    .main-content { 
        float: left; width: 84.615%; padding-left: 65px;    
        @include boxSizing(border-box);
        &.mc-right {  
            width: 75%; padding: 0 40px 0 0;  
        }
        &.full {width: 100%; padding-left: 0;} 
        h1 {
            font-size: 26px; margin-bottom: 20px; 
            &.fredoka {font-family: 'Fredoka One'; font-weight: normal;} 
            &.md {font-size: 22px;} 
            &.lg {font-size: 48px;} 
        } 
        h2.fredoka {font-family: 'Fredoka One'; font-weight: normal;}   
        h3.fredoka {font-family: 'Fredoka One'; font-weight: normal;}   
        h4.fredoka {font-family: 'Fredoka One'; font-weight: normal;}   
        h5.fredoka {font-family: 'Fredoka One'; font-weight: normal;}   
        h6.fredoka {font-family: 'Fredoka One'; font-weight: normal;}   
        a {
            color: #333;   
            &.btn-primary {color: #19515f;}             
            &.green {color: #ffb006;}             
            &.btn-post {color: #19515f;}            
        }
        .main-filter {     
            padding: 0 0 25px 0; border-bottom: 3px solid #e1e1e1; margin-bottom: 50px;       
            &:after {@extend .after_clear}  
            h1 {font-size: 24px; font-weight: bold; margin: 10px 0 0 0;}  
            &.no-border {border-bottom: 0; margin-bottom: 40px; padding-bottom: 0;}     
            a {color: #215f6f;}
        } 
        .top-mid { 
            margin-bottom: 35px; padding-top: 0;          
            h1 {margin-bottom: 0;}    
            &:after {@extend .after_clear}  
            .cl {
                display: inline-block; vertical-align: top; color: #888; font-size: 16px; 
                position: relative; line-height: 23px; 
                border-left: 1px solid #888; margin-left: 10px; padding: 0 0 0 12px;
                &:first-child {border:0; padding: 0; margin: 0;} 
            }
            .post-tm {                 
                span {color: #317a8d; font-weight: bold;}   
            }
            .date-tm {}
        }  
        .if-youtube {
            margin: 0 0 35px 0; 
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            iframe {width: 100% !important; height: 100% !important; position: absolute; top: 0; left: 0;}
        }
    }  
    .article {
        p {
            font-size: 16px; line-height: 34px; color: #555; margin: 1px 0 35px 0;
            &.med {font-size: 18px;} 
        } 
        h3 {
            font-size: 24px; line-height: 34px; color: #23443d; margin: 1px 0 35px 0;
            &.med {}
        } 
        ul {
            margin:0 0 35px 20px; 
            li {
                font-size: 16px; line-height: 30px; color: #333; margin: 0 0 5px 0;
                position: relative; padding: 0 0 0 3px;
                &:before {
                    width:10px; height: 10px; content: ""; left: -18px; top: 10px;  
                    background: #317a8d; position: absolute; 
                    @include borderRadius(50%); 
                }
                ul,ol {
                    margin-bottom: 15px;
                }
                ol {
                    margin-bottom: 15px; padding-top: 5px;
                    li {
                        list-style: decimal; 
                        &:before {display:none;} 
                    }
                }
            }             
        }
        ol {
            margin:0 0 35px 20px;  
            li {
                list-style: decimal; font-size: 16px; line-height: 30px; color: #333; margin: 0 0 5px 0;
                padding: 0 0 0 3px;
                ol {margin-bottom: 15px;} 
                ul {
                    margin-bottom: 15px; padding-top: 5px;
                    li {
                        list-style: none;  
                    }
                }
            }
        }
        img {
            margin-bottom: 15px;
        }
    }  
    &.rm-2 {
        /*padding-bottom: 20px;*/
        padding-bottom: 50px; 
        .wrapper {width: 1170px;}  
    }
    &.rm-event {
        background: url('../images/material/bg-retail-detail.png') repeat center top;
    }
    .nav-part {
        margin: 0 0 35px 0; 
        ul {
            margin: 0;
            &:after {@extend .after_clear}  
            li {
                float: left; margin: 0; padding: 0; background: #d3d3d3;
                &:before {display:none;} 
                a {
                    display: block; height: 50px; padding: 0 25px; line-height: 50px; color: #888;                     
                }
                &:first-child {@include borderRadius(4px 0 0 4px);}  
                &:last-child {@include borderRadius(0 4px 4px 0);} 
                &:hover { 
                    a {text-decoration: none;} 
                }
                &.active {
                    background: #19515f; 
                    a {
                        color: #fff; text-decoration: none; 
                    }
                }
            }
        }
    }

    .notfound.article { 
        width: 410px; margin: 0 auto; padding: 125px 0 0 0;  
        min-height: 300px; text-align: center; 
        .c4-title {font-size: 100px; color: #000; margin: 0 0 20px 0; line-height: 0;}   
        .subtitle {font-size: 40px; margin: 0 0 25px 0; color: #ffb006; font-family: 'Fredoka One';} 
        p {font-size: 18px; color: #fff; line-height: 30px;}    
    }

    &.wrap-event {
        position: relative; padding-bottom: 70px; padding-top: 0; margin-top: -25px;  
        .wrapper {z-index: 5;}
        &:before {
            position:absolute; width: 100%; height: 70px; content: ""; left: 0; top: 0; z-index: 2; 
            background: #fff; 
        }
        &:after {
            position:absolute; width: 100%; height: 100%; content: ""; left: 0; bottom: 0; z-index: 1; 
            background: url('../images/material/bg-retail-detail.png') repeat 0 bottom; 
        }
    }

}
.sidebar-filter { 
    padding: 0 0 30px 0; margin: 0 0 30px 0; border-bottom: 1px solid #e1e1e1; 
    &:last-child {padding:0; margin: 0; border: 0;}  
    .filter-title {font-size: 19px; font-weight: bold; color:#111; margin: 0 0 20px 0;}  
    .filter-list {  
        ul {
            li { 
                a { 
                    display: block; padding: 9px 0; text-transform: uppercase;
                    font-size: 16px; color:#333; 
                    &:hover,
                    &.active {color:#317a8d; font-weight: bold;}   
                } 
                &.dropdown { 
                    .parent {
                        a {
                            display: inline-block; position: relative;  
                            vertical-align: middle; 
                        }
                        .ico {
                            display: inline-block; position: relative;  
                            vertical-align: middle; 
                            width: 20px; height: 16px; padding: 0 0 0 5px; cursor: pointer; 
                            &:after {
                                position:absolute; width: 16px; height: 16px; content: "+"; 
                                top: 0; right: 0; text-align: center; line-height: 14px; 
                                font-size: 18px; 
                            }
                        }
                        &.active {
                            a {color:#317a8d; font-weight: bold;}                             
                            .ico {
                                &:after {
                                    content:"-"; color:#317a8d; font-weight: bold; 
                                }
                            }                            
                        }
                    } 
                }
                ul {
                    padding: 0 0 10px 15px; display: none; 
                    li {
                        a {font-size: 14px; color:#777; padding: 6px 0;}  
                    }
                } 
            }
        }
    }
    .filter-video {
        a {            
            figure {
                position: relative; overflow: hidden;  
                img {
                    @include transition(all .2s ease-in-out);
                }
            }
            &:hover { 
                img {
                    @include transform(scale(1.1)); 
                }
            }
        }        
    }
    &.is-brand { 
        .filter-title {margin-bottom: 15px;}  
        .filter-check {
            .checkbox {
                position: relative; margin: 0 0 13px 0; 
            }
            input {
                position: absolute; width: 100%; height: 100%; padding: 0; cursor: pointer; 
                z-index: 2; background: none;                     
                & + label {
                    position: relative;
                    cursor: pointer;
                    padding: 0;
                }
                & + label:before {
                    position: absolute; width: 15px; height: 16px; content: ""; top: 0; left: 0;  
                    background: url('../images/material/ico-check.png') no-repeat 0 0; 
                }
                &:disabled + label:before {  
                    background-position: 0 0;
                    
                }                     
                &:checked + label:before {
                    background-position: 0 bottom;    
                }
            }
            .label {
                display: block; position: relative; z-index: 1; padding: 0 0 0 28px; color:#777;
                background: none; 
            }  
        }
    }
    &.is-video { 
        .filter-video { 
            a {
                display: block; color: #555; 
            }
            figure {
                margin: 0 0 15px 0; position: relative; line-height: 0;  
                img {
                    width: 100%;  
                }
                iframe {width: 100% !important; height: 100% !important;z-index: 1;}
            }
            p {margin: 0;} 
            
        }
    }
}

.selectbox {
    display: inline-block; height: 44px; line-height: 44px; position: relative;  
    background: #eee; vertical-align: top; min-width: 200px; 
    @include borderRadius(4px);  
    &:after {
        position:absolute; width: 14px; height: 100%; content: ""; right: 15px; top: 0; 
        background: url('../images/material/ico-arrow-v.png') no-repeat center center; 
    }
    select {
        padding: 0 45px 0 17px; background: none; height: 100%; border: 0; width: 100%;
        line-height: 44px; display: block; cursor: pointer; position: relative; z-index: 3;
        color: #888; 
        option {
            word-wrap: break-word;
        }
    }
    &.full-white {
        width: 100%; background: #fff; height: 50px; line-height: 50px;  
    }
    &.selectRetail {
        width: 250px;
    }
}
.select2-container {
    background: none; height: 100%; vertical-align: top;
    .select2-selection--single {
        border: 0; background: none;
        height: 100%;
        .select2-selection__rendered {
            height: 100%; line-height: 45px; padding: 0 40px 0 15px; z-index: 5; position: relative; 
        }
        .select2-selection__arrow {display: none;}  
    }
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 0; 
}
.select2-dropdown {
    border:0; background: #eee; @include borderRadius(4px); 
    &.largedrop {width: 290px !important; margin-left: -40px;}  
}
.select2-search--dropdown {
    margin-bottom: 15px; padding: 0; 
    .select2-search__field {
        height: 40px; line-height: 40px; border: 0; padding: 10px; 
    } 
}
.select2-container--open .select2-dropdown--below { 
    border-top: 10px solid #fff; padding: 18px 18px 28px 18px; 
}
.select2-results__option {padding: 7px 10px; line-height: 20px;}    

.select2-container--open .select2-dropdown.select2-std {
    border: 1px solid #ccc; padding: 10px; 
    .select2-search--dropdown {display: none;} 
    .select2-results__option {line-height: 20px;}
}
.select2-container--open .select2-dropdown.select2-default { 
    border: 1px solid #ccc; padding: 10px;     
    .select2-results__option {line-height: 20px;}
}


.std {
    .left {
        &.fish {margin-left: 110px;}   
    } 
    .col {
        display: inline-block; margin-right: 25px; vertical-align: top; 
        &:last-child {margin-right:0;} 
    } 
    label {line-height: 44px;}
    .fm-search {
        line-height: 44px; height: 44px; background: #eee; position: relative; 
        @include borderRadius(4px); 
        @include placeholder{color: #888;}  
        input {  
            background: none; padding: 0; border:0; 
            &[type="text"] {padding:0 45px 0 17px; min-width: 160px;}    
            &[type="submit"] {  
                position: absolute; width: 45px; height: 100%; top: 0; right: 0; cursor: pointer;  
                background: url('../images/material/ico-search-2.png') no-repeat center center; 
            }
        } 
    }         
}


.product-list {  
    &:after {@extend .after_clear}
    .list-item {
        float: left; width: 25%; margin: 0 0 4% 0; position: relative; padding: 15px 15px;    
        text-align: center;  
        @include boxSizing(border-box);    
        @include transition(all .25s ease-in-out);    
        &:nth-child(4n+4) {margin-right: 0;}              
        figure { 
            height: 202px; margin: 0 0 35px 0; overflow: hidden; text-align: center; line-height: 0; 
            img {
                /*width: 100%; */
                height: 100%;
                object-fit: cover;
                object-position: center center;
                @include transition(all .25s ease-in-out);
            }    
        } 
        .title {
            font-size: 16px; line-height: 24px; /*max-height: 50px;*/ overflow: hidden; 
            margin-bottom: 10px;
        }  
        .short-description {
            font-size: 13px; line-height: 20px; max-height: 60px; overflow: hidden; color:#666;
            p {
                font-size: 13px; line-height: 20px; margin: 0; color:#666;
            }
        }  
        &:hover {  
            @include boxShadow(0px 0px 15px 0px #a1a0a0);  
            figure { 
                img {
                    @include transform(scale(0.95));
                }
            }
        }
    } 
    &.other-list {
        .list-item {margin-bottom: 0;} 
    }    
}
.other-product { 
    margin-top: 55px; 
    h3 {
        margin-bottom: 35px; border-bottom: 1px solid #e1e1e1; padding-bottom: 15px;
    } 
} 
.ribbon {
    position: absolute; height: 23px; line-height: 23px; color: #2194b2; top: -4px; right: 0; 
    /*background: #c2c2c2; */
    background: #ffb006;  
    padding: 0 13px; font-size: 13px; font-weight: 650;  
    @include borderRadius(4px); 
    &:before {
        position:absolute; width: 17px; height: 23px; content: ""; top: 0; left: -14px; 
        background: url('../images/material/ribbon-arrow.png') no-repeat 0 0; 
    }
    &.disabled {  
        background: #d6d6d6; color: #808080;  
        &:before {
            background: url('../images/material/ribbon-arrow-disabled.png') no-repeat 0 0;
        }
    } 
    &.relative {
        position: relative; display: inline-block; vertical-align: top; top: 0; 
        &.mg-left {margin-left: 25px;}    
    }
}
.watermark {
    position: absolute; width: 100px; height: 38px; top: 10px; left: 20px; z-index: 6;  
}


.frame-listing {  
    &.disabled {display: none;} 
    .row {margin:0 0 0 0; &:after{@extend .after_clear}}  
    .list-item {
        float: left; width: 31.66%; margin: 0 2.5% 4% 0; min-height: 100px; position: relative;               
        @include boxSizing(border-box);    
        figure {
            line-height: 0; margin-bottom: 20px; height: 250px; overflow: hidden; position: relative; 
            @include borderRadius(9px); 
            img {
                width: 100%; height: 100%; object-fit: cover;   
                @include borderRadius(9px); 
                @include transition(all .3s ease-in-out);
            }   
            &.hg-auto { 
                height: auto; 
            }                    
        }
        iframe {width: 100% !important; height: 100% !important;}
        .if-video {  
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            iframe {width: 100% !important; height: 100% !important; position: absolute; top: 0; left: 0;}
        }    
        .text {            
            .date { 
                color: #2194b2; 
                font-size: 16px; font-weight: bold; margin-bottom: 15px;   
                position: relative; 
            } 
            .name-toko { 
                font-size: 16px; font-weight: bold; margin:-5px 0 20px 0; color: #fff;     
                position: relative; 
            }  
            .title { 
                font-size: 18px; font-weight: bold; margin-bottom: 12px;
                /*height: 20px; overflow: hidden;  */
            }  
            .short-descrip {
                font-size: 16px; line-height: 24px; 
                p {line-height: 24px; margin: 0;}  
            }
            .post-by {
                color: #666; margin-top: 10px; 
                .by {font-weight: bold; color: #317a8d; padding: 0 0 0 5px;}     
            }
            .cate-by {
                color: #666; margin-top: 15px; 
                .by {font-weight: bold; color: #317a8d; padding: 0 0 0 5px;}  
            }
            .cate {
                span {
                    display: inline-block; border-right: 1px solid #333; padding: 0 8px 0 0; 
                    margin: 0 6px 0 0;
                    &:last-child {padding-right:0; margin-right: 0; border: 0;}    
                }
            }
        }
        &:nth-child(3n+3) {margin-right: 0;}   
        &:hover { 
            figure {
                img {
                    @include transform(scale(1.1)); 
                }
            }    
        }  
        &.hv_vote {
            .text {
                .flag {
                    position: absolute; right: 0; top: -4px; text-align: right;
                    &:after {content:""; display: block; clear: both;}
                    .ribbon {
                        display: inline-block; position: relative; top: 0; float: right;
                        &:last-child {margin-right: 20px;}
                        &:first-child {margin-right: 0px;}
                    }
                    .vote-ico {font-size: 13px; display: inline-block; margin-right: 20px; padding-left: 26px; background: url('../images/material/vote.png')no-repeat left center; line-height: 23px; background-size: 20px;margin-top: -4px;}
                }
            }
        }
    }   
    &.full {
        background: #215f6f; padding: 45px 40px; margin-bottom: 50px; 
        @include borderRadius(4px);               
        .list-item {
            width: 100%; margin-right: 0;
            &:last-child {margin-bottom:0;} 
            figure {
                float: left; width: 34.189%; margin: 0; height: 270px;   
                @include boxSizing(border-box);
                @include borderRadius(9px);
            }  
            .text {
                float: left; width: 65.811%; padding: 0 0 0 50px; color: #cedfe3;  
                @include boxSizing(border-box);
                .title {
                    font-size: 24px; color: #fff; font-family: 'Fredoka One'; font-weight: normal;
                    line-height: 28px;
                }
                .date {
                    /*color: #00f8fb; */
                    color: #ffb006;  
                    font-weight: 550;
                } 
                 .short-descrip {
                    margin-bottom: 25px; line-height: 26px;    
                }
            }      
            &:hover { 
                figure {
                    img {
                        @include transform(scale(1));  
                    }
                }    
            }       
        }
        &.past { 
            background: #fff; border:4px solid #e1e1e1;      
            /*@include boxShadow(0px 0px 10px 0px #a1a0a0);*/             
            .list-item { 
                .text {
                    color:#555;
                    .title {color: #333;}   
                } 
                .date {color:#19515f;} 
            }
        }
    }
    &.other {  
        background: #fff; padding: 45px 40px; margin-bottom: 0px;   
        @include borderRadius(4px); 
        .main-filter {
            margin-bottom: 25px; 
            h1 {margin: 0; }
        } 
        .txt {font-size: 16px; color: #215f6f; font-weight: bold; text-transform: uppercase; line-height: 26px;} 
        .list-item {     
            margin-bottom: 0;   
            .short-descrip {}  
            figure {}                      
        }
        &.ot-2 {
            padding: 0;
        }
    }
    &.marginbottom { 
        margin-bottom: 40px;  
    }      
    &.medium { 
        .list-item { 
            width: 100%; margin: 0 0 30px 0; 
            figure {
                height: 170px; margin: 0 0 15px 0;     
            }
            .text {
                .short-descrip {}
            } 
            &:last-child {margin-bottom: 0;} 
        }
    }  
    &.col-2 {     
        padding: 60px 0 0 0;    
        h4 {margin-bottom: 25px;} 
        .list-item {
            width: 47.5%; margin:0 5% 0 0; 
            &:nth-child(2n+2) {margin-right: 0;}  
            figure {
                height: 335px; 
                img {
                    @include borderRadius(0); 
                }
                &.hg-auto {height: auto;} 
            }             
        }
    }
}

.paging {
    padding: 20px 0 0 0; border-top: 1px solid #e1e1e1;  
    ul {
        text-align: center; position: relative; 
        &:after {@extend .after_clear}  
        li {
            display: inline-block; padding: 0 5px;  
            a {
                display: block; color: #555; padding: 6px 9px;      
                @include borderRadius(3px); 
                &:hover,
                &.active {
                    background:#19515f; color: #fff;  
                }
                &.active {cursor: default;} 
            }
            &.prev,
            &.next {                     
                a {
                    padding-left: 0; padding-right: 0;  
                    &:hover {
                        background:none; color: #555;  
                    }
                }
            }  
            &.prev {
                float: left;                    
            }
            &.next {
                float: right;                    
            } 
        }
    }
    &.no-border {border: 0; padding-top: 0;} 
}

.box-address { 
    padding: 20px 30px; background: #f1f1f1;  
    .row {
        display: table; width: 100%; position: relative; 
        &:after {@extend .after_clear}  
        .cl { 
            display: table-cell; padding: 3px 3px; line-height: 24px; vertical-align: top; 
            &:nth-child(1) {width: 30%; font-weight: bold;}  
            &:nth-child(2) {width: 70%; color: #555;}
        }
    }
    &.v2 {
        padding: 20px 35px; margin-bottom: 25px;       
        .row {
            padding: 0 0 0 17px; 
            &:before {
                width:10px; height: 10px; content: ""; left: 0; top: 10px;  
                background: #317a8d; position: absolute; 
                @include borderRadius(50%); 
            }
            .cl { 
                &:nth-child(1) {width: 22%;} 
                &:nth-child(2) {width: 78%;} 
            } 
        }
    }
    &.col-2 {
        &:after {@extend .after_clear} 
        .row {
            float: left; width: 50%; padding-right: 25px; 
            @include boxSizing(border-box); 
            .cl {
                &:nth-child(1) {width: 40%;}
                &:nth-child(2) {width: 60%;} 
            }
        }
    }
}
.box-cate-item {    
    padding: 20px 25px; background: #f1f1f1; margin: 0 0 25px 0; display: table; width: 100%;  
    @include boxSizing(border-box);    
    &:after {@extend .after_clear}
    .item {
        display: table-cell; padding: 0 10px 0 0; vertical-align: top; margin: 0 -4px 0 0;  
        position: relative;          
        @include boxSizing(border-box);     
        .cl { 
            display: block; vertical-align: top; margin-right: 0; font-size: 14px;  
            @include boxSizing(border-box);  
            &.ico {
                padding: 0 7px 0 0;width: 35px; 
                position: absolute; top: 0; left: 0;                  
            }      
            &.txt {
                line-height: 16px;
                margin-left: 35px;  
            }
        }       
        &:nth-child(1) {width: 16.666%;}       
        &:nth-child(2) {width: 16.666%;}       
        &:nth-child(3) {width: 27%;}    
        &:nth-child(4) {width: 23%;}    
        &:nth-child(5) {width: 16.666%;}     
        &:last-child {margin-right: 0;}      
    }  
}
.box-btn {  
    padding: 20px 0 0 0;  
}
.row-certificate {
    padding: 0 0; margin-bottom: 50px;  
    &:after {@extend .after_clear}
    .col {
        float: left;
        @include boxSizing(border-box);
        &:first-child {width:40.6%; padding-left: 70px;}   
        &:last-child {width:59.4%; padding:0 70px 0 40px;}    
        h4 {font-weight: bold; margin-bottom: 20px;}  
    }
    .row {
        &:after {@extend .after_clear}  
        .list {
            float: left; margin: 0 17px 0 0; 
            &:last-child {margin-right:0;} 
        }
    }
}
.row-description {
    padding: 40px 70px; background: #f5f5f5; 
}
.col-accordion {   
    .acclist {        
        .acc-title {  
            cursor: pointer; font-size: 18px; font-weight: bold; padding: 16px 45px 16px 0;  
            border-bottom: 2px solid #bebebe; position: relative; 
            &:after { 
                position:absolute; width: 16px; height: 16px; content: ""; top: 15px; right: 15px; 
                background: url('../images/material/ico-plus.png') no-repeat center center; 
            }    
            &.open {
                &:after {
                    background: url('../images/material/ico-minus.png') no-repeat center center; 
                }
            }
        }
        .acc-descrip { 
            padding: 28px 0;  
        }
    }
}
.table-std { 
    overflow-y:hidden; overflow-x: auto; 
    table { 
        width: 100%;  
        thead {
            th {
                padding: 22px 10px; background: #19515f; color:#fff; text-align: center; 
                &:first-child {padding-left:30px;}  
                &:last-child {padding-right:30px;}  
            }
        }
        tbody {
            td {
                padding: 22px 10px; background: #fff; text-align: center; border-bottom: 1px solid #f3f3f3; 
                position: relative; 
                &:first-child {
                    padding-left:30px;
                    &:before {
                        content: ""; 
                        position: absolute; width: 30px; height: 1px; background:#ffff; left: 0; bottom: -1px;  
                    }     
                }  
                &:last-child {
                    padding-right:30px; 
                    &:after {
                        content: ""; 
                        position: absolute; width: 30px; height: 1px; background:#ffff; right: 0; bottom: -1px;  
                    } 
                }
            }
            tr {
                &:last-child {
                    td {border-bottom:0;}
                }
            }
        }
        tfoot {
            td {
                padding: 22px 10px; background: #fff; border-bottom: 1px solid #f3f3f3; 
                position: relative; 
                &:first-child {
                    padding-left:30px;
                    &:before {
                        content: ""; 
                        position: absolute; width: 30px; height: 1px; background:#ffff; left: 0; bottom: -1px;  
                    }     
                }  
                &:last-child {
                    padding-right:30px; 
                    &:after {
                        content: ""; 
                        position: absolute; width: 30px; height: 1px; background:#ffff; right: 0; bottom: -1px;  
                    } 
                }
            }
            tr {
                &:first-child {
                    td {border-top: 1px solid #f3f3f3;}
                }
                &:last-child {
                    td {border-bottom:0;}
                }
            }

        }
    }
}


.row-about {     
    &:after {@extend .after_clear} 
    .row {&:after {@extend .after_clear}} 
    .col {
        float: left; width: 42.74%; 
        @include boxSizing(border-box);   
        &.text {
            width: 57.26%; padding: 20px 0 0 60px;                  
            p {line-height: 34px; margin-bottom: 35px;}                
        }
    }
}
.sitemap {      
    .row {
        padding: 20px 0 0 0;  
        &:after {@extend .after_clear}  
        .col {
            float: left; width: 25%; padding: 0 45px 0 0; 
            @include boxSizing(border-box); 
            h3 {
                font-size: 18px; font-weight: bold; margin-bottom: 7px;  
                a {
                    color: #333;  
                }
                &.alone {margin-bottom: 20px;}
            }
            ul {
                padding: 0 0 30px 0;    
                li {
                    a {
                        display: inline-block; font-size: 16px; padding: 5px 0; color: #555;
                        &:hover {
                            color:#317a8d; 
                        }
                    }  
                }
            } 
        }
    } 
}

.space-v {height: 80px;}  
.tag-small {
    position: absolute; left: 50%; top: -10px; z-index: 5;  
    @include transform(translate(-50%,0)); 
    &.v2 {
        left: 100%; margin-left: -105px;     
        @include transform(translate(0,0));
    }
}  
.retail-listing {
    padding-top: 15px;  
    &:after {@extend .after_clear};  
    .list-item {
        float: left; width: 30.3333%; margin: 0 3% 50px 0; position: relative;  
        @include boxSizing(border-box); 
        .col {
            float: left; @include boxSizing(border-box); 
            &.figure {
                line-height: 0; width: 41.74%; position: relative; 
                height: 165px; 
                .img {position: relative; overflow: hidden; height: 100%;}  
                img {
                    object-position: center center;
                    width: 100%; height: 100%; object-fit: cover; 
                    @include transition(all .25s ease-in-out);   
                }                  
            } 
            &.text {
                width: 58.26%; padding: 12px 0 0 30px;   
                h4 {font-size: 18px; font-weight: bold; margin: 0 0 10px 0;line-height: 22px;}    
                .shortdes {
                    line-height: 24px; margin: 0 0 10px 0; 
                }
                .telp {
                    font-size: 16px; position: relative; padding: 0 0 0 34px; line-height: 24px;   
                    color: #333; z-index: 5; 
                    &:before {
                        position:absolute; width: 24px; height: 24px; content: ""; left: 0; top: 0; 
                        background: url('../images/material/ico-telp-2.png') no-repeat center center; 
                    }
                }
            }
        }
        &:hover { 
            .col {
                &.figure { 
                    .img img {@include transform(scale(1.1));} 
                }
            }
        }
    }
}
.box-share {  
    margin-bottom: 25px;  
    &:after {@extend .after_clear} 
    .cl {
        float: left; margin: 0 10px 0 0; line-height: 25px;     
        a {
            display: inline-block; width: 25px; height: 25px; text-indent: -999999px;  
            margin:0 2px; 
            &.sh-fb {background: url('../images/material/sh-fb.png') no-repeat center center;}   
            &.sh-tw {background: url('../images/material/sh-tw.png') no-repeat center center;} 
            &.sh-email {background: url('../images/material/sh-email.png') no-repeat center center;} 
            &.sh-wa {background: url('../images/material/sh-wa.png') no-repeat center center;} 
            &:hover {
                opacity: 0.7; 
            }
        }
    }
    &.no-margin {margin-bottom: 0;}    
}
.box-large { 
    margin: 0 0 50px 0; position: relative;   
    .row {&:after {@extend .after_clear}}
    &:after {@extend .after_clear}
    .col {
        float: left; width: 42.74%; position: relative;     
        @include boxSizing(border-box);  
        &.figure {         
            text-align: center;    
            img {  
                width: 100%; 
                height: 100%; 
                object-fit: cover; object-position: center center;
            } 
            iframe {
                width: 100% !important; height: 100% !important; 
            }
        }  
        &.text {
            width: 57.26%; padding: 10px 0 0 40px;  
        }
    }    
    .retailSlider {
        .slide {
            line-height: 0; position: relative;  
        }
        figure {
           /*height: 100%; */
           height: 500px;
        }
        iframe {  
            width: 100% !important; height: 100% !important; position: absolute; top: 0; left: 0;
        }
        /*.if-video {  
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            iframe {width: 100% !important; height: 100% !important; position: absolute; top: 0; left: 0;}
        } */
        /*.slick-dots {     
            > li {
                display: none;
            }   
            > li ~ li {
                display: inline-block
            }
        }*/
    }
    &.event {
        background: #1f697c; padding: 50px 70px; margin: 0; color: #fff;    
        @include borderRadius(4px 4px 0 0);          
        &:before,
        &:after { 
            position:absolute; width: 65px; height: 100%; content: ""; top: 0;
        }   
        &:before {left:0; background: url('../images/material/bg-garnis-1.png') no-repeat center center;} 
        &:after {right:0; background: url('../images/material/bg-garnis-2.png') no-repeat center center;} 
        .figure {
            line-height: 0; width: 41.4%; overflow: hidden; 
            height: 400px;           
            @include boxShadow((20px 0 15px -8px rgba(0, 0, 0, 0.2), -20px 0 15px -8px rgba(0, 0, 0, 0.2)));
            @include borderRadius(12px);
            img {
                @include borderRadius(12px);
            } 
            .if-video {height: 100%;} 
        } 
        .text {   
            width: 58.6%; 
            p {color: #fff;} 
            .name-toko {
                margin: -12px 0 20px 0;  
            }
        }
        .date {color: #ffb006; font-weight: 550; margin: -10px 0 15px 0; font-size: 14px;}   
        .box-address { 
            color: #333; @include borderRadius(4px); 
        }
        &.event-2 {
            padding: 0; color: #333; background: none;  
            .row {margin-bottom: 45px;}
            &:before,
            &:after { 
                display:none;
            } 
            .figure {
                height: 320px; 
                @include boxShadow((20px 0 15px -8px rgba(0, 0, 0, 0.2), -20px 0 15px -8px rgba(0, 0, 0, 0.2)));
                iframe {
                    width: 100% !important; height: 100% !important;  
                }
            } 
            .text {    
                padding-top:0;  
                h1 {margin-bottom: 15px;}               
                h2 {margin-bottom: 15px;}               
                p {color: #333; line-height: 30px; margin-bottom: 15px;}    
            }
            ul {
                li {line-height: 26px; margin-bottom: 3px;} 
            }
        }
        &.ltr {
            .row {
                border-bottom: 1px solid #ddd; margin: 0 0 70px 0; padding: 0 0 70px 0; 
                .col {
                    &.text {
                        float: left; padding: 10px 40px 0 0; 
                    }
                    &.figure {
                        float: right; 
                    }                    
                }
                &:nth-child(even) {
                    .col {
                        &.text {
                            float: right; padding: 10px 0 0 40px;  
                        }
                        &.figure {
                            float: left; 
                        }
                    }
                }
                &:last-child {border-bottom: 0; margin-bottom: 0;}
            }
        }
        .row.full {
            padding: 30px 120px 30px 120px; position: relative;  
            .col {
                float: none; 
                &.text {
                    width: 100%; padding: 0;
                    p {
                        font-size: 20px; line-height: 34px; 
                    }
                }
                &.figure {
                    width: 100%; padding: 0; position: relative; 
                    overflow: visible; 
                    img {
                        position: relative; z-index: 2;
                    }
                    .if-video {
                        position: relative; z-index: 2; 
                        @include borderRadius(12px);
                        iframe {
                            @include borderRadius(12px); 
                        }
                    }
                    &:before,
                    &:after { 
                        position:absolute; width: 108px; height: 100%; content: ""; top: 0;
                        z-index: 1;
                    }   
                    &:before {
                        left:-85px; 
                        background: url('../images/material/bg-garnis-fig.png') no-repeat center center;                         
                    } 
                    &:after {
                        right:-85px;   
                        background: url('../images/material/bg-garnis-fig.png') no-repeat center center;
                        @include transform(rotate(-180deg));
                    }    
                }  
            }
        }
    }
    &.pets {
        &:before {display:none;}
        &:after {display:none;}        
    }
    &.md {
        .col {
            &.figure {
                width: 40.6%;  
                text-align: center;   
                .productSlide {
                    margin-bottom: 35px; 
                    .slide {}
                }  
                .thumbSlide {
                    .slide {
                        width: 100px; height: 100px; border:1px solid #fff; position: relative; 
                        cursor: pointer; margin: 0 5px 0 0; text-align: center;      
                        @include boxSizing(border-box); 
                        &:before {
                            position:absolute; width: 100%; height: 100%; border:1px solid #e1e1e1;
                            content: ""; left: 0; top: 0; 
                            @include boxSizing(border-box); 
                        }  
                        &.slick-current {
                            border-color: #317a8d; 
                            &:before {border-color:#317a8d;}  
                        }    
                        img {display: inline-block;}            
                    }
                    .slick-arrow {
                        &.slick-next {right: -25px;}  
                        &.slick-prev {left: -30px;}  
                    }
                }             
            }
            &.text {
                width: 59.4%; 
            }
        }

    }
}

.text-listing { 
    padding: 40px 55px; border: 4px solid #e1e1e1; margin-bottom: 40px; background: #fff;   
    @include borderRadius(4px);   
    .row {
        margin: 0 0 30px 0;
        &:last-child {margin-bottom:0;} 
        h4 {margin: 0 0 15px 0; font-weight: bold;}    
        ul {
            margin:0;  
            &:after {@extend .after_clear}   
            li {
               float:left; width: 25%; list-style: none; position: relative; padding: 0 0 0 12px; 
               font-size: 15px; 
               @include boxSizing(border-box); 
               &:before {
                    position:absolute; content: "-"; top: 0; left: 0; background: none; width: auto; height: auto; 
               }
            }
        }
    }
}

.row-event { 
    padding: 50px 70px; background: #fff;   
    @include borderRadius(0 0 4px 4px);
    .part-bottom {
        padding: 25px 48px; background: #e1e1e1; margin-bottom: 60px;  
        @include borderRadius(4px); 
        &:after {@extend .after_clear} 
        .cl {            
            &.left {
                padding: 5px 0 0 0; width: 55%;  
                p {margin: 0;font-size: 18px;}
            }
            &.right {text-align: right; width: 45%;}   
        }
        &.v2 {
            padding: 35px 30px;  
        }
    }
    &.ev-pets {
        padding: 70px; 
        .part-bottom {
            margin-bottom: 0; 
        }
    }
}

.part-vote {
    padding: 25px 40px; background: #19515f; color: #fff; margin: 0 0 30px 0;  
    @include borderRadius(4px); 
    &:after {@extend .after_clear}
    .left {
        padding: 10px 0 0 0; 
        p {line-height: 30px; font-size: 20px; margin: 0; color: #fff;}  
    } 
    input.btn.btn-primary.vote {
        border: 0; padding: 0 35px 0 60px;   
        @include transition(all .2s ease-in-out);  
        background:#ffb006 url('../images/material/ico-love.png') no-repeat 30px 50%;    
        &:hover {
            background:#ffa406 url('../images/material/ico-love.png') no-repeat 30px 50%; 
        } 
        &.disabled {
            background:#d7d7d7 url('../images/material/ico-love.png') no-repeat 30px 50%;  
            cursor: default; opacity: 0.8;   
        }  
    }
}

.form-container {
    width: 915px; margin:auto; position: relative; padding: 50px; 
    background: url('../images/material/bg-form.png') repeat center top;  
    @include boxSizing(border-box); 
    &:before {
        position:absolute; width: 245px; height: 304px; content: ""; left: -80px; bottom: 0; 
        background: url('../images/material/garnis-larger.png') no-repeat 0 0; z-index: 1; 
    }
    .inner { 
        position: relative; z-index: 2; background: #ececec; padding: 60px 75px; 
        min-height: 500px;      
        @include boxSizing(border-box); 
        p {
            line-height: 30px; 
        }
    }
}
.form-std {
    .field-group {
        margin: 0 0 25px 0;
        &:after {@extend .after_clear}  
        &.group-btn { 
            padding: 10px 0 0 0;  
            .right {margin-top: 15px;}     
        } 
        .radio-group { 
            padding: 10px 0 0 0; 
            &:after {@extend .after_clear} 
        }  
        .radio {    
            position: relative; margin: 0 75px 0 0; float: left;   
            input {
                position: absolute; width: 100%; height: 100%; padding: 0; cursor: pointer; 
                z-index: 2; background: none;                    
                & + label { 
                    position: relative;
                    cursor: pointer;
                    padding: 0;
                }
                & + label:before {
                    position: absolute; width: 20px; height: 20px; content: ""; top: 0; left: 0;  
                    background: url('../images/material/ico-radio.png') no-repeat 0 0; 
                }
                &:disabled + label:before {   
                    background-position: 0 0;
                    
                }                     
                &:checked + label:before {
                    background-position: 0 bottom;    
                }
            }
            .label { 
                display: block; position: relative; z-index: 1; padding: 0 0 0 28px; color:#777; 
                line-height: 20px;  
            }
        }
    } 
    @include placeholder{color:#888;} 
    label {display: block; margin: 0 0 10px 0; font-weight: 550;} 
    input {
        display: block; height: 50px; line-height: 50px; padding: 0 18px; border: 0; 
        color:#888; width: 100%; background: #fff;      
        @include boxSizing(border-box);    
        @include borderRadius(4px);    
    }
    textarea { 
        display: block; height: 110px; line-height: 18px; padding: 14px 18px; border: 0; 
        color:#888; width: 100%; max-width: 100%; resize: none;        
        @include boxSizing(border-box);    
        @include borderRadius(4px);    
        &.editor {
            height: 195px; 
        }
    }
    .error {
        display: block; color: red; font-size: 13px; margin: 0 0 8px 0; line-height: 16px;
    }
    .note-small {
        display: block; color: #999; font-size: 13px; margin: 7px 0 0 0; line-height: 16px;   
    }
    .input_file { 
        width: 100%; background: #fff; 
        display: block; height: 50px; line-height: 50px; padding: 0 105px 0 18px; border: 0; 
        color:#888; width: 100%;
        @include boxSizing(border-box);  
        @include borderRadius(4px); 
        &:after {
            position:absolute; width: 100px; height: 50px; line-height: 50px; color:#888; font-size: 15px; 
            content: "Browse"; top: 0; right: 0; background: #d0d0d0; text-align: center; cursor: pointer;  
            z-index: 4;
            @include borderRadius(0 4px 4px 0);  
        }
        input {z-index: 5; padding: 0 105px 0 18px; cursor: pointer; height: 100%;}
        span {
            position: absolute; z-index: 1; color:#888; padding: 0 105px 0 18px; width: 100%;
            left: 0; top: 0; overflow: hidden; 
            /*overflow-x: auto;    */
            white-space:nowrap;
            @include boxSizing(border-box); 
            &.filled { 
                &:after {
                    position:absolute; width: 115px; height: 100%; background: #fff; right: 0; top: 0;   
                    content: "";                    
                } 
            } 
            /*&::-webkit-scrollbar { 
                height: 5px; 
            }
            &::-webkit-scrollbar-track {
              background: #f1f1f1; 
            }
            &::-webkit-scrollbar-thumb {
              background: #888; 
            }
            &::-webkit-scrollbar-thumb:hover {
              background: #555; 
            }*/
        }  
    }
}

 .form-search {
    margin: 30px 0 50px 0;  
    fieldset {position: relative; border-bottom: 1px solid #31798c; margin: 0 0 10px 0;}  
    input[type="text"] {
        width: 100%; font-size: 32px; line-height: 32px; padding: 0 50px 14px 0; 
        font-weight: 300; border:0;   
        @include boxSizing(border-box);  
    }
    input[type="submit"] {    
        position: absolute; width: 34px; height: 100%; content: ""; right: 0; top: 0;  
        padding: 0; border:0; cursor: pointer; 
        background: url('../images/material/ico-search.png') no-repeat center center;    
    }  
}
.result-search {    
    padding: 25px 0 25px 0;   
    .col { 
        border-bottom: 1px solid #31798c; padding: 0 0 24px 0; margin: 0 0 45px 0;    
        a {
            display: block;     
            h3 {font-size: 25px; color: #333; font-weight: bold; margin: 0 0 22px 0;}     
            &:hover {
                h3 {color: #317a8d;}  
            }
        }
        &:last-child {border-bottom: 0;} 
    }
}
.row-404 {
    height: 745px; background: #14424b; 
    background: url('../images/material/bg-retail-detail.png') repeat-x center center;   
    @include boxSizing(border-box);  
} 


.float_ico {
    position: fixed; z-index: 999; right: -10px; bottom: 230px; @include transform(scale(1));
    &.absSroll {
        position: absolute;animation-name: bounching;animation-duration: .4s;
    }
}

@keyframes bounching {
    0% { bottom: 440px; }
    25% { bottom: 445px; }
    50% { bottom: 440px; }
    75% { bottom: 445px; }
    100% { bottom: 440px; }
}

.row-cms {
    padding: 0 0 10px 0; border-top: 1px solid #ddd; padding: 40px 0 20px 0; 
    font-size: 14px; 
    p {
        line-height: 28px;
    } 
    &.no-border {
        border-top: 0; 
    }
}

.flag-detail {
    position: relative; text-align: right; margin-bottom: 15px;
    .ribbon {
        position: relative; display: inline-block;
        &.vote {margin-right: 20px;}
    }
    .vote-ico {font-size: 13px; display: inline-block; margin-right: 20px; padding-left: 26px; background: url('../images/material/vote.png')no-repeat left center; line-height: 23px; background-size: 20px; color: #2194b2;font-weight: 600;}
}



/* footer
----------------------------------------------------------------------------------------------*/
footer {
    background: #141414; border-top: 4px solid #f26722; padding: 50px 0 50px 0; color: #5b5b5b;  
    position: relative; z-index: 5;  
    a {
        color: #5b5b5b;
        &:hover {color:#a1a1a1;}  
    }         
    .foot-top {
        border-bottom: 1px solid #1b1b1b; padding: 0 0 20px 0; margin-bottom: 30px; 
        &:after {@extend .after_clear}
        .telp {
            color: #a1a1a1; padding: 0 0 0 40px; position: relative; line-height: 32px; 
            &:after {
                position:absolute; width: 32px; height: 32px; content: ""; left: 0; top: 0; 
                background: url('../images/material/ico-telp.png') no-repeat center center; 
            }
        }
        .nav-foot {
            float: right; 
            ul {
                font-size: 0;
                li {
                    display: inline-block; font-size: 15px;   
                    a {display: block; padding: 0 23px; line-height: 32px;}  
                }
            }
        }
    }
    .foot-bottom {
        &:after {@extend .after_clear} 
        .copyright {
            float:left; margin: 5px 0 0 0; line-height: 16px;   
        }
        .box-social {
            font-size: 0; float: right;   
            a {
                display: inline-block; font-size: 15px; margin: 0 7px; width: 30px; height: 30px; 
                text-indent: -999999px; 
                &.sm-fb {background: url('../images/material/sm-fb.png') no-repeat center center;} 
                &.sm-tw {background: url('../images/material/sm-tw.png') no-repeat center center;} 
                &.sm-ig {background: url('../images/material/sm-ig.png') no-repeat center center;} 
                &:hover {
                    opacity: 0.7; 
                }
            }
        }
    }
}


body.lock {overflow-y: hidden;}  
.wrap_popup {
    display: none; 
    background: rgba(0,0,0, 0.7);   
    position: fixed;
    width: 100%;
    height: 100%;    
    z-index: 999999;
    top: 0;
    left: 0;
    overflow-y: auto;
    .pop_inner {
        display: table;
        height: 100%;
        width: 100%;
        position: absolute;
        .middle_pop {
            display: table-cell;
            vertical-align: middle;            
        }
    }   
    .bg-overlay {
        position: absolute; width: 100%; height: 100%;  
        z-index: 78787; left: 0; top: 0; margin: 0;
    }
}
#boxPop { 
    min-width: 700px;    
    max-width: 700px;   
    min-height: 200px;
    margin: 0 auto 50px auto;   
    position: relative;
    z-index: 878787;     
    
    .close_pop { 
        position: absolute;
        width: 40px;
        height: 40px;        
        right: -40px; 
        top: 0px;  
        cursor: pointer;
        z-index: 5;   
        background: #ffb006;
        span {
            display: block; width: 24px; height: 2px; background: #fff; position: absolute;
            top: 50%; left: 50%; margin: -1px 0 0 -12px;
            @include transform(rotate(45deg));  
            &:last-child {
                @include transform(rotate(-45deg));
            }
        }      
    }   
    .content_pop {         
        background: none;position: relative; font-size: 18px; 
        .if-video { 
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            iframe {width: 100% !important; height: 100% !important; position: absolute; top: 0; left: 0;}
        }           
        img {width: auto; height: auto;}     
        p {line-height: 30px;}     
        .img {
            text-align: center;             
            img {object-fit: contain; margin: auto;} 
        } 
    }             
}
.sc_content {   
    z-index: 22;     
    color: #bebebe; 
    .inner {
        padding: 0 0 0 0; 
    }        
}


.popup-menu {
    position: fixed; width: 100%; height: 100%; top: 0; left: 0; z-index: 898989; padding: 20px;
    background: #154850; display: none; 
    background: url('../images/material/bg-popup-menu.png') no-repeat 0 bottom; 
    background-size: cover;  
    @include boxSizing(border-box);
    @include transform(scale(1.05)); 
    .inner {
        margin: 0; background: #fff; height: 100%; position: relative; padding:0 70px;    
    }
    .close-pop { 
        position: absolute; cursor: pointer; width: 30px; height: 28px; top: 30px; right: 35px;  
        z-index: 6;  
        span {
            position: absolute; width: 100%; height: 5px; background: #19515f; left: 5px;
            @include borderRadius(3px);
            &:nth-child(1){
                top:0;
                @include transformorigin(left center); 
                @include transform(rotate(45deg));
            }
            &:nth-child(2){ 
                top:21px; 
                @include transformorigin(left center);
                @include transform(rotate(-45deg));
            }
        }
    }
    .nav-mob {
        position: relative; width: 100%; height: 100%; padding: 90px 0 150px 0;     
        @include boxSizing(border-box); 
        ul {
            text-align: center; position: relative; height: 100%; overflow-y: auto;
            li {                  
                a {
                    display: inline-block; color: #19515f; font-size: 20px; font-weight: bold;
                    text-transform: uppercase; padding: 17px 0; vertical-align: middle; 
                    position: relative; 
                } 
                .parent {
                    display: inline-block; vertical-align: middle; 
                    a {
                        padding-left: 35px; padding-right: 5px;   
                    }
                    span.ico {
                        display: inline-block; 
                        position: relative; vertical-align: middle; width: 30px; height: 18px; 
                        padding: 0 0 0 0; cursor: pointer; text-align: center; 
                        &:before {
                            position: absolute; width: 16px; height: 18px;  content: "+"; font-weight: bold; 
                            top: 0; right: 0; left: 0; bottom:0; text-align: center; line-height: 16px;  
                            font-size: 18px; color: #19515f; margin: auto; 
                        }
                    }
                    &.active { 
                        span.ico:before {content:"-";} 
                    }
                }
                ul {
                    padding-bottom: 15px; display: none;     
                    li {
                        a {
                            font-size: 16px; text-transform: capitalize; padding: 8px 0; 
                            font-weight: normal; 
                        }
                    }
                }
            }
        }
    }
    .bottom-pop {
        position: absolute; width: 100%; left: 0; bottom: 0; padding: 20px 70px 30px 70px;    
        border-top: 1px solid #ddd; 
        @include boxSizing(border-box); 
        &:before, 
        &:after {
            position:absolute; width: 70px; height: 1px; background: #fff; top: -1px;
            content: ""; 
        } 
        &:before {left: 0;}  
        &:after {right: 0;} 
    }
}

.overlay-side {
    display: none; 
}
.close-side {display: none;}